<template>
  <div class="productBody">
    <div v-for="(item, index) in list.list" :key="index">
      <span class="title">{{ item.colName }}:</span>
      <span class="conten" v-if="item.colValue && item.colValue.indexOf('|') == -1"
        >{{ item.colValue }}
      </span>
      <ul class="type4" v-else>
        <li v-for="(el, eindex) in item.colValue && item.colValue.split('|')" :key="eindex">
          {{ el }}
        </li>
      </ul>
    </div>
    <div v-if="list.batchId == 1395 || list.batchId == 1407">
      <span class="title" style="color: red;">备注:</span>
      <span class="conten" style="font-weight: bold;color: red;">本产品在保险期间内由中国人民财产保险股份有限公司杭州市分公司承保产品质量保证保险。</span>
    </div>
    <div class="bottomBut">
      <van-button
        type="info"
        round
        style="width: 100%; margin-bottom: 10px"
        @click="
          $router.push({
            name: 'certificate',
            params: { data: list.traceList[0] },
          })
        "
        >区块链证书</van-button
      >
      <van-button
        v-if="list.codeSum && list.codeSum != 0"
        style="width: 100%; background-color: darkorange; color: #fff"
        round
        @click="handleCheckJump"
        >防伪查询</van-button
      >
    </div>
  </div>
</template>
<script>
export default {
  props: {
    list: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {};
  },
  mounted(){
    console.log(this.list);
  },
  methods: {
    replace(e) {
      if (e) {
        return e.replace(/。/g, "</br>");
      }
    },
    jsonPrase(str) {
      console.log(str);
      if (str) {
        if (str.indexOf("]") < 0) {
          return str;
        } else {
          const arr = JSON.parse(str);
          return arr.toString();
        }
      }
    },
    handleCheckJump() {
      let batchId = localStorage.getItem("batchId");
      let isIndex = localStorage.getItem("indexNo");
      this.$router.push({
        name: "check",
        query: {
          batchId,
          noIndex: isIndex && isIndex != "" ? false : true,
        },
      });
    },
    getAudit(type) {
      if (type == "1") {
        return "检验结论";
      }
      if (type == "2") {
        return "检测结论";
      }
      if (type == "3") {
        return "合格评定结论";
      }
    },
  },
};
</script>
<style scoped>
.productBody {
  padding: 10px 20px;
  line-height: 26px;
  font-size: 0.8rem;
}
.textColor {
  color: #ff0000;
}
.textColor1 {
  color: #ffba00;
}
.productBody .title {
  color: #000;
  font-weight: 600;
}
.productBody .conten {
  margin-left: 10px;
  word-wrap: break-word;
  word-break: break-all;
}
.bottomBut {
  text-align: center;
  margin-top: 20px;
}
.flexdiv {
  display: flex;
  align-items: center;
}
.type4 {
  list-style: none;
}
</style>

