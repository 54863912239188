<template>
  <div class="information">
    <van-collapse v-model="activeNames">
          <van-collapse-item v-for="(list,indexs) in list.traceList" :key="indexs" :name="indexs"  :title="list.traceName" >
            <div class="contentTop" v-if="list.blockHash != null && list.blockId !=null">
              <div class="topIcon"><i class="el-icon-date"></i></div>
              <div  v-for="(itme,index) in list.nodeList" :key="index">
                  <div v-if="itme.dtlType == '1'">
                    <div style="margin: 10px;color: #000;">{{itme.dtlTitle}}:</div>
                    <div v-for="img in itme.urlList" :key="img" style="padding: 10px;">
                      <el-image  :src="img" class="topImg"></el-image>
                    </div>
                  </div>
                  <div class="ui_img" v-else-if="itme.dtlType == '3'" >
                    <div style="margin: 10px;color: #000;">{{itme.dtlTitle}}:</div>
                    <div v-for="(data,index) in itme.urlList" :key="index" @click="imgClick(data)" style="padding: 10px;">
                      <el-image :src="data.dtlContent" style="width:100;height:100%" >
                        <div slot="error" class="image-slot">
                            <p>点击预览PDF</p>
                        </div>
                      </el-image>
                    </div>
                </div>
              <div  v-else-if="itme.dtlType == '2'">
                <div style="margin: 10px;color: #000;">{{itme.dtlTitle}}:</div>
                <div v-for="(data,index) in itme.urlList" :key="index" style="margin:10px">
                  <Video :videoUrl='data'/>
                </div>
              </div>
              <div  v-else style="padding: 5px;">
                <div>
                  <span style="margin: 10px;color: #000;">{{itme.dtlTitle}}</span>:
                  <span style="margin: 10px;color:#7f8082;">{{itme.dtlContent}}</span>
                </div>
              </div>
              </div>
            </div>
          </van-collapse-item>
    </van-collapse>
  </div>
</template>
<script>
import Video from '@/components/video.vue'
export default {
  components:{ Video },
  props:{
    list:{
      type: Object,
      required: true
    }
  },
  data(){
    return{
        activeNames: []
    }
  },
  created(){
    console.log(this.list)
  },
  methods:{
    // PDF
    imgClick(e){
        console.log(e)
        location.href = e
    },
  }
}
</script>
<style scoped>
.contentTop{
  border: 1px solid #bfbfbf;
  border-radius: 10px;
}
.topIcon{
  background: #005bac;
  color: #fff;
  font-size: 21px;
  padding: 5px 10px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
.topImg{
  padding: 10px;
  
}
.textContent{
  padding: 10px;
  color: #000;

}
.textContent_span{
  color: #666;
}
</style>

